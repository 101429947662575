<template>
    <section>
        <div style="height:calc(100vh - 92px);">
            <tabs :tabs="rutas" class="border-b-gris" />
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    middleware:['auth'],

    data(){
        return {
            rutas: [
                { titulo: 'Créditos', ruta: 'admin.tesoreria.creditos', can: 'tab1_tesoreria_creditos' },
                { titulo: 'Liquidacíon de comisiones', ruta: 'admin.tesoreria.liquidacion-comisiones', can: 'tab1_tesoreria_alianza' },
                // { titulo: 'Flujo de dinero', ruta: 'admin.tesoreria.flujo-dinero', can: 'tab1_tesoreria_flujo_dinero' },
                { titulo: 'Pasarela de pagos', ruta: 'admin.tesoreria.pasarela-pagos', can: 'tab1_tesoreria_alianza' }
            ]
        }
    },
    watch: {
        '$route.name'(){
            this.$store.commit('cedis/setCargandoTerminado', true)
        }
    },
    mounted(){
        if(`${this.$config.menu.creditos}` == 0)this.rutas.splice(this.rutas.findIndex( el => el.ruta == 'admin.tesoreria.creditos'), 1)
    }
}
</script>

<style lang="scss" scoped>
.br-t-12{
    border-radius: 12px 12px 0px 0px;
}
</style>
