<template>
    <section class="position-relative">
        <cargando v-if="cargando" />
        <div class="row mx-0 align-items-center">
            <div class="col-auto f-500 f-18 text-general px-0">
                Lista de empacadores
            </div>
            <div class="mn-pill ml-3 bg-general3 text-white px-2">
                {{ activosTotal }}
            </div>
            <div class="btn-general f-14 px-3 ml-auto" @click="crear">
                Crear empacador
            </div>
            <div class="col-xl-2 col-lg-3">
                <el-input v-model="buscar" prefix-icon="icon-search" autocomplete="nope" class="w-100 br-20" placeholder="Buscar empacador" size="small" @input="filtro" />
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-4" style="height:calc(100vh - 126px)">
            <div class="row mx-0">
                <template v-if="activosTotal > 0">
                    <div v-for="empacador in activos.filter(empacador => !buscar || JSON.stringify(empacador).toLowerCase().includes(buscar.toLowerCase()))" :key="empacador.id" class="card-picking mr-4 cr-pointer mt-2 mb-3" @click="$router.push({name: 'admin.picking.ver', params: {id_picking: empacador.id}})">
                        <img :src="empacador.foto" width="100%" height="162" class="br-t-12 obj-cover" />
                        <div class="row mx-0 py-2">
                            <div class="col-12 text-general text-center f-16 tres-puntos">
                                {{ empacador.nombre }}
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 text-gris2 text-center f-14 tres-puntos">
                                {{ empacador.cedis != null ? empacador.cedis : 'No registra' }}
                            </div>
                        </div>
                        <div class="row mx-0 mt-2">
                            <div class="col px-2 border-right">
                                <p class="text-gris2 f-10 text-center">
                                    Alistado
                                </p>
                                <p class="text-gris2 text-center">
                                    <i class="icon-cart-arrow-down f-18" />
                                    <span class="f-500">
                                        {{ empacador.total_alistados }}
                                    </span>
                                </p>
                            </div>
                            <div class="col px-2 border-left">
                                <p class="text-gris2 f-10 text-center">
                                    Empacado
                                </p>
                                <el-tooltip placement="bottom" content="En el último mes" effect="light">
                                    <p class="text-gris2 text-center">
                                        <i class="icon-packed f-18" />
                                        <span class="f-500">
                                            {{ empacador.total_empacados }}
                                        </span>
                                    </p>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="col">
                        <p class="text-general2 f-12">Actualmente no existen empacadores activos</p>
                    </div>
                </template>
            </div>
            <div class="row mx-0 align-items-center my-4">
                <div class="col-auto f-500 f-18 text-general px-0">
                    Empacadores inactivos
                </div>
                <div class="mn-pill ml-3 bg-general3 text-white px-2">
                    {{ inactivosTotal }}
                </div>
            </div>
            <div class="row mx-0">
                <template v-if="inactivosTotal > 0">
                    <div v-for="empacador in inactivos.filter(empacador => !buscar || JSON.stringify(empacador).toLowerCase().includes(buscar.toLowerCase()))" :key="empacador.id" class="card-picking mr-4 cr-pointer" @click="$router.push({name: 'admin.picking.ver', params: {id_picking: empacador.id}})">
                        <img :src="empacador.foto" width="100%" height="162" class="br-t-12 obj-cover" />
                        <div class="row mx-0 py-2">
                            <div class="col-12 text-general text-center f-16 tres-puntos">
                                {{ empacador.nombre }}
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 text-gris2 text-center f-14 tres-puntos">
                                {{ empacador.cedis != null ? empacador.cedis : 'No registra' }}
                            </div>
                        </div>
                        <div class="row mx-0 mt-2">
                            <div class="col px-2 border-right">
                                <p class="text-gris2 f-10 text-center">
                                    Alistado
                                </p>
                                <p class="text-gris2 text-center">
                                    <i class="icon-cart-arrow-down f-18" />
                                    <span class="f-500">
                                        {{ empacador.total_alistados }}
                                    </span>
                                </p>
                            </div>
                            <div class="col px-2 border-left">
                                <p class="text-gris2 f-10 text-center">
                                    Empacado
                                </p>
                                <el-tooltip placement="bottom" content="En el último mes" effect="light">
                                    <p class="text-gris2 text-center">
                                        <i class="icon-packed f-18" />
                                        <span class="f-500">
                                            {{ empacador.total_empacados }}
                                        </span>
                                    </p>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="col">
                        <p class="text-general2 f-12">Actualmente no existen empacadores inactivos</p>
                    </div>
                </template>
            </div>
        </div>
        <!-- <div class="custom-scroll overflow-auto pt-4" style="height:calc(100vh - 111px)">
        </div> -->
        <!-- Partials -->
        <modal-crear-empacador ref="crearEmpacador" @actualizar="getEmpacadores" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Service from '~/services/empacadores/empacadores'
export default {
    components: {
        modalCrearEmpacador: () => import('./partials/modalCrearEmpacador')
    },
    data(){
        return {
            cargando: false,
            activos: [],
            inactivos: [],
            activosTotal: 0,
            inactivosTotal: 0,
            buscar: '',
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        }),
    },
    watch:{
        id_cedis(){
            this.getEmpacadores()
        },
    },
    mounted(){
        this.getEmpacadores();
    },
    methods: {
        async getEmpacadores(){
            try{
                this.cargando = true;
                const {data} = await Service.getEmpacadores()
                this.activos = data.activos;
                this.inactivos = data.inactivos;
                this.activosTotal = data.activos.length;
                this.inactivosTotal = data.inactivos.length;
            } catch (e){
                this.error_catch(e)
            } finally{
                this.cargando = false
            }
        },
        filtro(){
            let activosTotal = this.activos.filter((item) => !this.buscar || JSON.stringify(item.nombre).toLowerCase().includes(this.buscar.toLowerCase()));
            let inactivosTotal = this.inactivos.filter((item) => !this.buscar || JSON.stringify(item.nombre).toLowerCase().includes(this.buscar.toLowerCase()));
            this.activosTotal = activosTotal.length;
            this.inactivosTotal = inactivosTotal.length;
        },
        crear(){
            this.$refs.crearEmpacador.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
.card-picking{
    width: 162px;
    height: 283px;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 1px 2px 8px #6B707414;
}
</style>
